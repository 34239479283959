<template>
  <div class="mask" @click="onClose()">
    <div class="mask-main" @click.stop>
      <div class="appleline"></div>
      <div class="code-input-main" @click="onFocus">
        <div class="code-input-main-item">{{code[0]}}<div class="item-cursor" v-if="isFocus&&code.length===0"></div></div>
        <div class="code-input-main-item">{{code[1]}}<div class="item-cursor" v-if="isFocus&&code.length===1"></div></div>
        <div class="code-input-main-item">{{code[2]}}<div class="item-cursor" v-if="isFocus&&code.length===2"></div></div>
        <div class="code-input-main-item">{{code[3]}}<div class="item-cursor" v-if="isFocus&&code.length>=3" :class="{'end': code.length===4}" ></div></div>
        <input id="codeinput" class="code-input-input" v-model="code" maxlength="4" type="number" @blur="onBlur"/>
      </div>
      <div class="login-code-btn" @click="onSubmit">{{i18n.login}}</div>
      <div class="again" @click="onClose">{{i18n.retry}}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  watch: {
    isFocus (val, oldVal) {
      if (!val) {
        this.inputStatus = false
      }
    },
    code (val, oldVal) {
      if (val.length > 4) {
        this.code = val.slice(0, 4)
      }
    }
  },
  computed: {
    i18n () {
      return this.$t('logincode')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
      code: '',
      isFocus: false,
      inputStatus: false
    }
  },
  methods: {
    onFocus () {
      let node = document.getElementById('codeinput')
      node.focus()
      this.isFocus = true
    },
    onBlur () {
      this.isFocus = false
    },
    onSubmit () {
      console.log('确定的登陆')
      this.$emit('submit', this.code)
    },
    onClose () {
      this.code = ''
      this.$emit('close', false)
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;
  .mask-main{
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px 80px 150px;
  }
  .appleline{
    background-color: #EBEBEB;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto;
  }
  .code-input-input {
    height: 1px;
    width: 1px;
    position: absolute;
    left: -100px;
    bottom: 0;
    font-size: 10px;
    caret-color: transparent;
    color: transparent;
    z-index: -999;
  }
  .code-input-main {
    margin-top: 20px;
    height: 180px;
    line-height: 180px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .code-input-main-item {
    width: 100px;
    flex: 1;
    margin: 0 20px;
    border-bottom: 5px solid #000;
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #000;
    .item-cursor {
      width: 1px;
      height: 100px;
      background-color: #161616;
      animation: blink 1s infinite steps(1, start);
    }
    .end {
      position: absolute;
      right: 0;
    }
  }
  .login-code-btn{
    margin-top: 60px;
    background: linear-gradient(to right, #028BD2, #23C8EE);
    color: #fff;
    font-size: 36px;
    text-align: center;
    padding: 20px 0;
    border-radius: 20px;
  }
  .again{
    font-size: 28px;
    color: #666;
    text-align: center;
    margin: 40px 0 0;
  }
}
</style>
