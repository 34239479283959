<template>
  <div>
    <div v-if="payMode.pDailiId !== '234242686589589'" class="language">
      <span class="language-label" @click="showLangSelect">{{
        locale && locale.label
      }}</span>
      <span class="language-tip">LANGUAGE</span>
    </div>
    <img class="logo" :src="require('../../assets/imgs/logo.png')" alt="" />
    <div class="home-body">
      <div class="title">{{ i18n.powerbanktitle }}</div>
      <!-- <div class="options" v-if="!user" @click="loginOptionShow = true">{{ i18n.otheropt }}</div>
      <div class="options" v-else @click="onLogout">{{ i18n.logout }}</div> -->
      <div class="shopdetail">
        <img src="../../assets/imgs/clock.png" alt="" />
        <p class="top">{{ priceInfo }}</p>
        <div class="pricing" @click="showRentCostPop">
          {{ i18n.pricing }} <span class="pricingtip">i</span>
        </div>
      </div>
      <!-- <button class="loginbtn" v-if="user">
        <div class="userinfo">
          <img
            :src="loginMode === 'Google' ? require('../../assets/imgs/icon_googlepay.png') : loginMode === 'Apple' ? require('../../assets/imgs/icon_applepay.png') : loginMode === 'Facebook' ? require('../../assets/imgs/icon_facebook_unselected.png') : require('../../assets/imgs/icon_phone_selected.png')"
            alt="">
          <span>{{ user && user.pname }}</span>
        </div>
        <div class="logout" @click="onLogout">
          <span>{{ i18n.logout }}</span>
        </div>
      </button> -->
      <!-- <Google class="buttonDiv" v-if="!user" @success="loadUser(true)">
        <div id="buttonDiv"></div>
        <div class="googlewarp">
          <img src="../../assets/imgs/icon_googlepay.png" alt="">
          <span>{{ i18n.logingg }}</span>
          <div class="right"></div>
        </div>
      </Google> -->
      <!-- <Apple v-if="system === 'ios' && !user" @success="loadUser(true)">
        <button class="login-method" id="sign-in-with-apple-button">
          <img src="../../assets/imgs/icon_applepay.png" alt="">
          <span>{{ i18n.loginapple }}</span>
          <div class="right"></div>
        </button>
      </Apple> -->
      <!-- <div class="login-method" v-if="!user" @click="loginOptionShow = true">
        <img :src="require('../../assets/imgs/phone.png')" alt="">
        <span>{{ i18n.loginphone }}</span>
        <div class="right"></div>
      </div> -->
      <div v-if="rentBtnShow" class="rentbtn" @click="goPayprexx">
        {{ i18n.rent }}
      </div>
      <div v-if="paypalBtnShow" class="rentbtn" @click="onPaypal">
        {{ i18n.rent }}
      </div>
      <div class="agreen">
        {{ i18n.tips }}
        <a @click="viewAgreen(1)">{{ i18n.privacy }}</a>
        {{ i18n.and }}
        <a @click="viewAgreen(2)">{{ i18n.term }}</a>
      </div>
      <div
        class="linkblock"
        v-if="payMode && payMode.pDailiId === '234242686589589'"
      >
        <a href="https://www.livroreclamacoes.pt/Inicio/">{{
          i18n.complaint
        }}</a>
        <a href="https://www.ciab.pt/pt">{{ i18n.claimsettlement }}</a>
      </div>
      <!-- <div class="localtip">
        <img src="../../assets/imgs//icon_localeicon.png" alt="" @click="goGoogleApp">
        <div>
          <p class="tip1">{{ i18n.localtip1 }}</p>
          <p class="tip2">{{ i18n.localtip2 }}</p>
        </div>
      </div> -->
    </div>
    <div v-if="loginOptionShow" class="mask" @click="loginOptionShow = false">
      <div class="mask-main" @click.stop>
        <div class="appleline"></div>
        <div class="phonelogin">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phone"
            :placeholder="i18n.phonenumber"
          />
          <img
            src="../../assets/imgs/icon_next_circle_dark.png"
            alt=""
            @click="onSendCode"
          />
        </div>
        <div class="agreen">
          {{ i18n.tips }}
          <a @click="viewAgreen(1)">{{ i18n.privacy }}</a>
          {{ i18n.and }}
          <a @click="viewAgreen(2)">{{ i18n.term }}</a>
        </div>
      </div>
    </div>
    <popup-warp v-model="isCouponShow">
      <div>
        <input
          v-model="beforeCCode"
          type="text"
          class="couponipt"
          :placeholder="i18n.couponcode"
        />
        <div class="btn creditcard-btn" @click="onCouponCodeConfirm">
          {{ i18n.msg_fail_confirm }}
        </div>
      </div>
    </popup-warp>
    <popup-warp v-model="isphoneNumber">
      <div class="mask">
        <div class="mask-main" @click.stop>
          <div class="appleline"></div>
          <div class="phonelogin">
            <div class="area" @click="isAreaView = true">
              <span>+{{ areaCode }}</span>
              <div class="area-down"></div>
            </div>
            <input
              type="number"
              v-model="EasyPayPhone"
              :placeholder="i18n.phonenumber"
            />
          </div>
          <div class="phonelogin">
            <input
              type="text"
              v-model.trim="EasyPayEmail"
              :placeholder="i18n.email"
            />
          </div>
          <div class="phonelogin">
            <input type="text" v-model="name" :placeholder="i18n.name" />
          </div>
          <div class="btn creditcard-btn" @click="saveEasyPayCard('mbw')">
            {{ i18n.msg_fail_confirm }}
          </div>
        </div>
      </div>
    </popup-warp>
    <popup-warp v-model="payListShow">
      <div class="scroll-y">
        <!-- <div class="popup-card-list" v-if="payMode && payMode.pPaymentType === 'datatrans-web'"
          @click="onDataTransRecharge(false, '')">
          <div class="popup-card-item">
            <img :src="require('../../assets/imgs/icon_twint.png')" alt="">
            <span>TWINT</span>
          </div>
        </div> -->
        <div
          class="popup-card-list"
          v-if="
            payMode &&
            payMode.pPaymentType === 'EasyPay_CC' &&
            payMode.pDailiId !== '234242686589589'
          "
          @click="beforeRent()"
        >
          <div class="popup-card-item">
            <img
              :src="require('../../assets/imgs/icon_card_Unselected.png')"
              alt=""
            />
            <span>EasyPay</span>
          </div>
        </div>
        <div
          class="popup-card-list"
          v-if="payMode && payMode.pPaymentType.indexOf('Payprexx') !== -1"
          @click="onPayprexxSaveCard()"
        >
          <div class="popup-card-item">
            <img
              :src="require('../../assets/imgs/icon_card_Unselected.png')"
              alt=""
            />
            <span>Payprexx</span>
          </div>
        </div>
        <template v-if="payModeList.length > 0">
          <div
            v-for="item in payModeList"
            :key="item.pId"
            class="popup-card-list"
          >
            <div
              class="popup-card-item"
              v-if="
                item.phone === 'all' ||
                item.phone === system ||
                item.phone === browser ||
                (item.phone === 'phone' && system !== '')
              "
              :key="item.pId"
              @click="onRent(item)"
            >
              <img
                v-if="item.pPaymentType === 'PAYPAL'"
                :src="require('../../assets/imgs/icon_paypal.png')"
                alt=""
              />
              <img
                v-else
                :src="require('../../assets/imgs/icon_card_Unselected.png')"
                alt=""
              />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </template>
        <!-- <div class="popup-card-list" v-for="(item, i) in cardList" :key="item.pId" @click.stop="onPay(item.pId)">
          <p class="value">
            <span class="pBrand">{{ item.pBrand }}</span>
            <span>{{ item.pCardnumber }}</span>
          </p>
          <img class="deleteicon" v-if="i" @click.stop="removeCardConfirm(item.pId)"
            src="../../assets/imgs/icon_subtraction.png" alt="">
        </div> -->
      </div>
    </popup-warp>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <PreAuthorTip
      v-if="preAuthorShow"
      @close="(res) => (preAuthorShow = res)"
    ></PreAuthorTip>
    <RentCostTip
      v-if="rentCostShow"
      :jifei="(cabinet && cabinet.jifei) || 2"
      :jifeiDanwei="(cabinet && cabinet.jifeiDanwei) || 60"
      :fengding="(cabinet && cabinet.fengding) || 6"
      :yajin="cabinet.yajin"
      :currencyName="currencyName"
      :currency="currency"
      @close="(res) => (rentCostShow = res)"
    ></RentCostTip>
    <LoginCode
      v-if="loginCodeShow"
      @close="(res) => (loginCodeShow = res)"
      @submit="onLogin"
    ></LoginCode>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onStripeSetup">
          {{ i18n.paynow }}
        </div>
      </div>
    </popup-warp>
  </div>
</template>

<script>
import i18n from "../../i18n"
import { mapState, mapGetters } from "vuex"
import datatransMixin from "../../mixins/datatrans"
import userMixin from "../../mixins/user"
import stripeMixin from "../../mixins/stripe"
import { generateUUID } from "../../utils/index"
import { baseURL } from "../../utils/http"
import { getQueryString } from "../../utils/url"
import { SelectArea } from "../../components/select"
import PreAuthorTip from "./component/preauthortip.vue"
import RentCostTip from "./component/rentcosttip.vue"
import LoginCode from "./component/logincode.vue"
// import Google from '../../components/login/google.vue'
// import Apple from '../../components/login/apple.vue'
import { PopupWarp } from "../../components/popup"
import { USER_CAPTCHA } from "../../apis/user"
import { QR_CODE, ZH_CODE } from "../../apis/cabinet"
import {
  PAYMODE,
  COUPON_SEND,
  SAVE_PAYMENT_METHORD,
  SAVE_PAYMENT_CONFIRM,
  SAVE_CARD_TO_RENT,
  USER_CARD,
  REMOVE_CARD,
  EASYPAY_PREAUTH,
  EASYPAY_PREAUTH_CONFIRM,
  EASYPAY_SAVECARD,
  EASYPAY_SAVECARDCONFIRM,
  PAYPAL_SAVECARD,
  PAYPAL_SAVECARD_CONFIRM,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREAUTH_AUTO_CONFIRM
} from "../../apis/payment"
export default {
  mixins: [datatransMixin, userMixin, stripeMixin],
  components: {
    SelectArea,
    PreAuthorTip,
    RentCostTip,
    LoginCode,
    // Google,
    // Apple,
    PopupWarp
  },
  watch: {
    // 'payMode.pPaymentType': {
    //   handler(val, oldVal) {
    //     console.log('payMode', val)
    //     if (this.cabinet && this.token && this.qrCodeSessionId) {
    //       if (val.indexOf('Payprexx') !== -1) {
    //         this.onPayprexxSaveCard()
    //       } else {
    //         this.$toast(this.$t('loginborrow.daili_error'))
    //       }
    //     }
    //   },
    //   immediate: true, // 立即执行
    //   deep: true // 深度监听复杂类型内变化
    // },
    cabinet(val) {
      if (val) {
        console.log("设备信息", val)
        this.getPayMode()
      }
    },
    user(val) {
      if (val) {
        console.log("用户信息", val)
        this.getCardList()
      }
    },
    "payMode.pDailiId": {
      handler(val, oldVal) {
        // this.payprexxConfirm(16218512)
        console.log("pDailiId", val)
        if (val && val === "234242686589589") {
          this.$store.commit("lang", "pt-PT")
          i18n.locale = "pt-PT"
        }
      },
      immediate: true, // 立即执行
      deep: true // 深度监听复杂类型内变化
    }
  },
  computed: {
    ...mapState([
      "browser",
      "user",
      "token",
      "cabinet",
      "system",
      "qrcode",
      "agentId",
      "loginMode",
      "payprexxId",
      "payMode",
      "lang",
      "domain",
      "qrCodeSessionId",
      "returnAppUrl",
      "paymentId",
      "phone"
    ]),
    ...mapGetters(["locale"]),
    scanUrl() {
      if (this.qrcode || this.$route.query.qrcode) {
        return `${QR_CODE}?id=${this.qrcode || this.$route.query.qrcode}`
      }
      if (this.zhcode || this.$route.query.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    i18n() {
      return this.$t("loginborrow")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      return (
        (this.cabinet && this.cabinet.currency) ||
        (this.user && this.user.pcurrency) ||
        "CHF"
      )
    },
    currencyName() {
      return (
        (this.cabinet && this.cabinet.currencyName) ||
        (this.user && this.user.pcurrencyName) ||
        "CHF"
      )
    },
    priceInfo() {
      return this.$t("loginborrow.priceInfo", {
        currency: this.currency,
        jifei: (this.cabinet && this.cabinet.jifei) || 2,
        jifeiDanwei: (this.cabinet && this.cabinet.jifeiDanwei) || 60
      })
    }
  },
  data() {
    return {
      errstr: "",
      isAreaView: false,
      areaCode: "41",
      captchaUrl: "",
      code: "",
      uuid: "",
      // phone: '',
      cardNum: "",
      couponCode: "",
      beforeCCode: "",
      latitude: "",
      longitude: "",
      preAuthorShow: false,
      loginOptionShow: false,
      rentCostShow: false,
      loginCodeShow: false,
      isCreditcard: false,
      isCouponShow: false,
      payListShow: false,
      currentLoginMode: "phone",
      payModeList: [],
      cardList: [],
      cardMode: null,
      EasyPayPhone: "",
      easyPayConfirmCount: 6,
      // payMode: {
      //   pPaymentType: 'STRIPE_CARD'
      // },
      stripePayRequest: null,
      creditcard: null,
      clientSecret: null,
      creditOrder: null,
      isphoneNumber: null,
      // returnApp: '',
      EasyPayEmail: "",
      name: "",
      fiscalNumber: "",
      jumpTime: 5,
      jumpLink: "",
      jumpAppLink: "",
      rentBtnShow: false,
      paypalBtnShow: false
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    onSelct(val) {
      this.areaCode = val
    },
    showRentCostPop() {
      this.rentCostShow = true
    },
    goPayprexx() {
      if (
        this.payMode.pPaymentType &&
        this.payMode.pPaymentType.includes("STRIPE")
      ) {
        if (this.payModeList.length === 1) {
          this.onRent(this.payModeList[0])
        } else {
          this.payListShow = true
        }
      } else {
        window.location.href = this.jumpLink || this.jumpAppLink
      }
    },

    beforeRent() {
      const payId = new URLSearchParams(window.location.search).get(
        "payment_intent"
      )
      if (this.user && !payId) {
        if (!this.errstr) {
          if (
            this.payMode.pPaymentType &&
            this.payMode.pPaymentType.includes("STRIPE")
          ) {
            return this.user
          }
          if (this.cardList && this.cardList.length > 0) {
            this.onSaveCardToRent()
          } else if (this.payMode.pPaymentType.indexOf("Payprexx") !== -1) {
            this.onPayprexxSaveCard()
          } else {
            this.payListShow = true
          }
        } else {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: this.errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      }
    },
    viewAgreen(num) {
      if (this.lang === "pt-PT") {
        if (num === 1) {
          window.open(`${this.domain}/content/privacy_policy_pt-PT.html`)
        } else {
          window.open(`${this.domain}/content/user_agreement_pt-PT.html`)
        }
      } else {
        if (num === 1) {
          window.open(`${this.domain}/content/privacy_policy.html`)
        } else {
          window.open(`${this.domain}/content/user_agreement.html`)
        }
      }
    },
    onCouponCodeConfirm() {
      if (this.beforeCCode) {
        this.couponCode = this.beforeCCode
      } else {
        this.couponCode = ""
      }
      this.isCouponShow = false
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    goGoogleApp() {
      console.log(this.latitude, this.longitude)
      this.$router.push("/nearby")
      // if (!this.user) {
      //   this.$toast(this.i18nMsg.unlogin)
      //   return
      // }
      // window.location.href = `https://www.google.com/maps/dir/${this.latitude},${this.longitude}/${this.cabinet.latitude},${this.cabinet.longitude}`
    },
    onLogout() {
      console.log("退出==")
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.msg_logout,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.$store.commit("user", "")
        this.$store.commit("token", "")
        this.$store.commit("appleIdToken", "")
        // this.$store.commit('qrcode', '')
        this.payModeList = []
        this.cardList = []
        this.cardMode = null
      })
    },
    onRent(item) {
      this.$store.commit("payMode", item)
      if (this.user) {
        if (this.payMode.pPaymentType === "PAYPAL") {
          this.onPaypal()
        } else if (this.payMode.pPaymentType === "STRIPE_CARD") {
          this.onCreditcard(this.publicKey)
        } else if (!this.errstr) {
          this.saveEasyPayCard("cc")
        } else {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: this.errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
        // if (this.payMode.pPaymentType === 'datatrans-web') {
        //   this.saveDataTransCard() // datatrans保存卡
        // } else {
        // }
      }
    },
    onMbWay(item) {
      this.isphoneNumber = true
      this.payListShow = false
    },
    onScan() {
      let url = this.scanUrl
      this.inputCodeShow = false
      // this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.errstr = ""
          // this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          }
        },
        (error) => {
          this.$loading(false)
          if (error.includes("is offline") || error.includes("离线")) {
            this.errstr = this.$t("home.offline", { devicecode: this.qrcode })
          } else if (error.includes("Permission") || error.includes("权限")) {
            this.errstr = this.$t("home.permissionfail")
          } else if (error.includes("not bound") || error.includes("未绑定")) {
            this.errstr = this.$t("home.unbound", { devicecode: this.qrcode })
          } else if (error.includes("No battery") || error.includes("可用")) {
            this.errstr = this.$t("home.nobattery", { devicecode: this.qrcode })
          } else {
            this.errstr = error
          }
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: this.errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      )
    },
    getLocation() {
      if (navigator.geolocation) {
        let that = this
        navigator.geolocation.getCurrentPosition(
          function (res) {
            console.log("浏览器定位", res)
            that.latitude = res.coords.latitude
            that.longitude = res.coords.longitude
          },
          (err) => {
            console.log("geolocation fail", err)
          }
        )
      } else {
        this.$confirm({
          title: this.i18n.msg_fail_title,
          confirmText: this.i18nMsg.confirm,
          content: this.i18nMsg.browsernotsupport,
          showCancel: false
        })
      }
    },
    getCouponCode() {
      this.$loading(true)
      this.$get(
        `${COUPON_SEND}?code=${this.couponCode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    getCardList() {
      // this.$loading(true)
      this.$get(
        `${USER_CARD}?status=1&qrcode=${
          this.qrcode || this.$route.query.qrcode
        }&userEnd=web`,
        {},
        (resp) => {
          // this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
          if (resp.data && resp.data.length > 0) {
            this.cardMode = resp.data[0]
          } else {
            this.cardMode = null
          }
          this.beforeRent()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onPaypal() {
      this.$loading(true)
      let url = PAYPAL_SAVECARD
      this.$post(
        url,
        {
          paymentId: this.payMode.pId
        },
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            this.$loading(true)
            // this.$store.commit('paypalTradeNo', resp.data.tradeNo)
            this.$store.commit("paymentId", this.payMode.pId)
            window.location.href = resp.data.approve
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPaypal()
          })
          console.log(error)
        }
      )
    },
    onPaypalConfirm(paymentId, token) {
      this.$loading(true)
      let url = PAYPAL_SAVECARD_CONFIRM
      this.$post(
        url,
        {
          paymentId,
          token
        },
        (resp) => {
          console.log("onPaypalConfirm", resp)
          if (resp.code === 0) {
            setTimeout(() => {
              this.$loading(false)
              this.getCardList()
            }, 1000)
          }
        },
        (error) => {
          if (error === "正在请求中" || error === "Processing") {
            setTimeout(() => {
              this.onPaypalConfirm(paymentId, token)
            }, 1000)
          } else {
            this.$loading(false)
            console.log(error)
            this.$toast(error)
            this.paypalBtnShow = true
          }
        }
      )
    },
    // Easypay卡授权
    saveEasyPayCard(str) {
      console.log("this.payMode", this.payMode)
      if (!this.payMode || !this.payMode.pId) {
        return
      }
      let data = {}
      if (str === "mbw") {
        data = {
          paymentId: this.payMode.pId,
          returnUri: "/#/",
          method: str,
          phone: this.EasyPayPhone,
          fiscalNumber: "+" + this.areaCode,
          email: this.EasyPayEmail,
          name: this.name
        }
      } else if (str === "cc") {
        data = {
          paymentId: this.payMode.pId,
          method: str,
          returnUri: "/#/"
        }
      } else {
        data = {
          paymentId: this.payMode.pId,
          returnUri: "/#/"
        }
      }
      let url = `${EASYPAY_SAVECARD}`
      this.$loading(true)
      this.$post(
        url,
        data,
        (resp) => {
          this.$loading(false)
          console.log("saveEasyPayCard====", resp)
          if (str === "mbw") {
            this.saveEasyPayCardConfirm(resp.data.outTradeNo, resp.data.tradeNo)
            // this.$confirm({
            //   title: this.i18nMsg.msg_fail_title,
            //   content: this.i18nMsg.issuccesspreauth,
            //   confirmText: this.i18nMsg.retry,
            //   cancelText: this.i18nMsg.cancel
            // }).then(() => {
            //   this.saveEasyPayCardConfirm(resp.data.outTradeNo, resp.data.tradeNo)
            // })
          } else {
            window.location.href = resp.data.apiResult.method.url
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveEasyPayCard(str)
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    saveEasyPayCardConfirm(outTradeNo, tradeNo) {
      let url = `${EASYPAY_SAVECARDCONFIRM}`
      this.$loading(true)
      this.$get(
        url,
        {
          outTradeNo,
          tradeNo
        },
        (resp) => {
          if (resp.status || (resp.data && resp.data.status)) {
            this.onSaveCardToRent()
            this.$loading(false)
          } else if (this.easyPayConfirmCount > 0) {
            setTimeout(() => {
              this.saveEasyPayCardConfirm(outTradeNo, tradeNo)
            }, 1000)
            this.easyPayConfirmCount--
          }
          console.log("saveEasyPayCard====", resp)
          // this.$toast(this.i18nMsg.save_success)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          // this.$toast(error)
        }
      )
    },
    easyPayConfirm(tradeNo, payMode = "EasyPay") {
      this.$get(
        `${EASYPAY_PREAUTH_CONFIRM}?outTradeNo=${tradeNo}`,
        {},
        (resp) => {
          console.log("easyPayConfirm====", resp)
          if (
            resp.data &&
            resp.data.orderId &&
            resp.data.orderStatus === "预授权成功"
          ) {
            this.$router.replace(
              `/result?outTradeNo=${resp.data.orderId}&payMode=EasyPay`
            )
          } else {
            setTimeout(() => {
              this.onConfirmPreAuth(tradeNo, "fail")
            }, 1000)
          }
        },
        (error, code) => {
          if (code === 1) {
            setTimeout(() => {
              this.onConfirmPreAuth(tradeNo, "fail")
            }, 1000)
          } else {
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    // EasyPay 预授权
    onEasyPayCCPreauth(cardId) {
      // this.onScan()
      if (!this.EasyPayPhone) {
        this.$toast(this.i18n.phonenumber)
        return
      }
      const reg = /^\w+(-+.\w+)*@\w+(-.\w+)*.\w+(-.\w+)*$/
      if (!this.EasyPayEmail) {
        this.$toast(this.i18n.email)
        return
      }
      if (!reg.test(this.EasyPayEmail)) {
        this.$toast(this.i18n.msg_email_err)
        return
      }
      this.$loading(true)
      let data = {}
      if (cardId) {
        data = {
          qrcode:
            this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode,
          paymentId: this.payMode.pId,
          returnUri: "/#/",
          userCardId: cardId
        }
      } else {
        data = {
          qrcode:
            this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode,
          paymentId: this.payMode.pId,
          returnUri: "/#/",
          fiscalNumber: this.areaCode,
          phone: this.EasyPayPhone,
          email: this.EasyPayEmail,
          method: "mbw"
        }
      }
      this.$post(
        `${EASYPAY_PREAUTH}`,
        data,
        (resp) => {
          this.$loading(false)
          console.log("easyPayConfirm====", resp)
          if (resp.data && resp.data.out_trade_no) {
            this.onConfirmPreAuth(resp.data.out_trade_no)
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    onConfirmPreAuth(tradNo, status) {
      this.$confirm({
        title: this.i18nMsg.msg_fail_title,
        content:
          status === "fail"
            ? this.i18nMsg.msg_confirm_preAuth_fail
            : this.i18nMsg.msg_confirm_preAuth,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.easyPayConfirm(tradNo)
      })
    },
    removeCardConfirm(id) {
      this.$confirm({
        title: this.i18nMsg.msg_fail_title,
        content: this.i18nMsg.deletecard,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.removeCard(id)
      })
    },
    removeCard(id) {
      this.$loading(true)
      this.$get(
        `${REMOVE_CARD}/${id}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log("removecard====", resp)
          this.payListShow = false
          this.getCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.removeCard(id)
          })
          console.log(error)
        }
      )
    },
    getPayMode() {
      // this.$loading(true)
      this.$get(
        `${PAYMODE}?id=${
          this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
        }`,
        {},
        (resp) => {
          // this.$loading(false)
          if (resp.data) {
            let list = resp.data
            list = list.filter((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "PAYPAL") {
                v.label = "Paypal"
                v.phone = "all"
              } else if (
                v.pPaymentType === "EasyPay_CC" ||
                v.pPaymentType === "EasyPay"
              ) {
                v.label = "Credit Card"
                v.phone = "all"
              }
              return (
                v.label ||
                v.pPaymentType === "PAYPAL" ||
                v.pPaymentType === "datatrans-web" ||
                v.pPaymentType === "EasyPay_CC" ||
                v.pPaymentType.indexOf("Payprexx") !== -1
              )
            })
            console.log("支付方式===", list)
            this.payModeList = list
            if (list.length > 0) {
              this.$store.commit("payMode", list[0])
              if (list[0].pPaymentType === "datatrans-web") {
                this.loadDataTransScript()
              }
              if (
                list.filter((v) => v.pPaymentType.includes("STRIPE")).length
              ) {
                this.rentBtnShow = true
                this.loadStripeScript(list[0].publicKey)
              }
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_paymod,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    },
    jumpTimer() {
      if (
        this.jumpTime > 0 &&
        window.location.href.indexOf("moovenergy.app") !== -1
      ) {
        window.location.href = this.jumpLink
        setTimeout(() => {
          this.jumpTime--
          this.jumpTimer()
          console.log(this.jumpTime)
        }, 2000)
      } else {
        this.$loading(false)
        console.log("jumpTimer")
        this.rentBtnShow = true
        this.$confirm({
          title: this.i18n.msg_fail_title,
          confirmText: this.i18nMsg.confirm,
          content: this.i18nMsg.jumpfail,
          showCancel: false
        })
      }
    },
    jumpAppTimer() {
      if (
        this.jumpTime > 0 &&
        window.location.href.indexOf("moovenergy.app") !== -1
      ) {
        window.location.href = this.jumpAppLink
        setTimeout(() => {
          this.jumpTime--
          this.jumpAppTimer()
          console.log(this.jumpTime)
        }, 2000)
      } else {
        this.$loading(false)
        console.log("jumpAppTimer")
        this.rentBtnShow = true
        this.$confirm({
          title: this.i18n.msg_fail_title,
          confirmText: this.i18nMsg.confirm,
          content: this.i18nMsg.jumpfail,
          showCancel: false
        })
      }
    },
    onPayprexxSaveCard() {
      this.$loading(true)
      // this.onScan().then(() => {
      let RedirectUrl = `${process.env.VUE_APP_Domain}/#/`
      let data = {}
      if (this.qrCodeSessionId) {
        data = {
          paymentId: this.payMode.pId,
          successRedirectUrl:
            RedirectUrl +
            `?state=success${
              this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
            }`,
          failedRedirectUrl: RedirectUrl + `?state=failed`,
          cancelRedirectUrl: RedirectUrl + `?state=cancel`,
          qrCodeSessionId: this.qrCodeSessionId,
          amount: 100,
          List: "twint",
          returnApp: this.returnAppUrl,
          qrCode:
            this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
        }
      } else {
        data = {
          paymentId: this.payMode.pId,
          successRedirectUrl:
            RedirectUrl +
            `?state=success${
              this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
            }`,
          failedRedirectUrl: RedirectUrl + `?state=failed`,
          cancelRedirectUrl: RedirectUrl + `?state=cancel`,
          List: "twint",
          amount: 0,
          qrCode:
            this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
        }
      }
      this.$store.commit("qrCodeSessionId", "")
      this.$store.commit("returnAppUrl", "")
      this.$post(
        SAVE_PAYMENT_METHORD,
        data,
        (res) => {
          console.log(res)
          if (res && res.code === 0) {
            if (res.data.savePaymentResult.data[0].transactionId) {
              if (this.system === "ios") {
                let url = this.addStr(
                  res.data.savePaymentResult.data[0].appLink,
                  RedirectUrl,
                  '"url": "'
                )
                console.log(url)
                this.jumpAppLink = url
                this.rentBtnShow = true
                // window.location.href = url
                // setTimeout(() => {
                //   this.jumpAppTimer()
                // }, 3000)
                this.$loading(false)
              } else {
                let url = this.addStr(
                  res.data.savePaymentResult.data[0].appLink,
                  RedirectUrl,
                  "S.browser_fallback_url="
                )
                console.log(url)
                this.jumpAppLink = url
                this.rentBtnShow = true
                // window.open(url)
                // setTimeout(() => {
                //   this.jumpAppTimer()
                // }, 3000)
                this.$loading(false)
              }
            } else {
              console.log("junmsjfl111===")
              this.jumpLink = res.data.savePaymentResult.data[0].link
              this.rentBtnShow = true
              // window.location.href = res.data.savePaymentResult.data[0].link
              // setTimeout(() => {
              //   this.jumpTimer()
              // }, 3000)
              this.$loading(false)
              this.$store.commit(
                "payprexxId",
                res.data.savePaymentResult.data[0].id
              )
            }
          }
        },
        (err) => {
          this.$toast(err)
          this.$loading(false)
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      // })
    },
    addStr(oldStr, addItem, afterWhich) {
      // 在指定字符串后面添加指定字符串
      var strArr = oldStr.split("")
      // if (this.system === 'ios') {
      //   strArr.splice(oldStr.indexOf('"version"'), 0, `"referer_app_link": {"target_url": "", "url": "${addItem}", "app_name": "EXTERNAL_WEB_BROWSER"},`)
      // }
      // if (oldStr.indexOf(afterWhich) !== -1) {
      //   strArr.splice(oldStr.indexOf(afterWhich) + afterWhich.length, 0, addItem)
      // } else if (this.system === 'ios') {
      //   strArr.splice(oldStr.indexOf('"version"'), 0, `"referer_app_link": {"target_url": "", "url": "${addItem}", "app_name": "EXTERNAL_WEB_BROWSER"},`)
      // } else {
      //   strArr.splice(oldStr.indexOf('end'), 0, `S.browser_fallback_url=${addItem};`)
      // }
      console.log(strArr)
      return strArr.join("")
    },
    payprexxConfirm(id) {
      this.$get(
        SAVE_PAYMENT_CONFIRM,
        {
          // paymentId: 10,
          paymentId: this.payMode.pId,
          // userId: this.user.popenId,
          id: id
        },
        (res) => {
          console.log(res)
          if (res.data && res.data.data[0].status === "authorized") {
            this.onSaveCardToRent()
            this.$loading(false)
          } else if (res.data && res.data.data[0].status === "waiting") {
            setTimeout(() => {
              this.payprexxConfirm(id)
            }, 1000)
          }
        },
        (err) => {
          console.log(err)
          this.$toast(err)
        }
      )
    },
    onCode() {
      console.log(this.qrcode)
      if (this.qrcode || this.$route.query.qrcode) {
        this.onScan()
      }
      if (this.token) {
        this.loadUser()
        // this.onPayprexxSaveCard()
      }
    },
    onSaveCardToRent() {
      this.$loading(true)
      // this.onScan()
      this.$get(
        SAVE_CARD_TO_RENT,
        {
          qrcode:
            this.qrcode || this.cabinet.cabinetID || this.$route.query.qrcode
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          this.$router.replace(
            `/result?outTradeNo=${res.data.out_trade_no}&payMode=DataTrans`
          )
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onCreditcard(publicKey) {
      // console.log('publicKey', publicKey)
      let url = ""
      let datas = {}

      console.log(this, this.payType)
      // if (this.cabinet && this.payType === "YJ") {
      url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.cabinetID}`
      datas = {
        paymentId: this.payMode.pId,
        amount: this.cabinet.yajin
      }
      // } else if (this.price > 0 && this.payType === "FK") {
      //   url = STRIPE_PAYORDER
      //   datas = {
      //     outTradeNo: this.orderId,
      //     couponNo: null,
      //     paymentId: this.pId
      //   }
      // } else {
      //   url = STRIPE_RECHARGE_WALLET
      //   datas = {
      //     amount: this.price,
      //     paymentId: this.pId
      //   }
      // }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: "tabs"
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard(publicKey)
          })
          console.log(error)
        }
      )
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      window.stripe
        .confirmPayment({
          elements: vm.creditcard,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: window.location.href
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          }
        })
    },
    confirmSetup(id) {
      this.$loading(true)
      this.$get(
        // `${STRIPE_PREAUTH_AUTO_CONFIRM}`,
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`,
        {},
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (this.payType !== "YJ") {
            this.$router.replace(
              `/result?outTradeNo=${resp.data.orderId}&payMode=stripe&payType=YJ`
            )
            return
          }
          this.$router.replace(
            `/result?outTradeNo=${resp.data.orderId}&payMode=stripe`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    }
  },
  async created() {
    if (!this.user) {
      if (!this.phone) {
        this.$store.commit("phone", this.$getMoble(6))
        await this.onSendCode(this.phoneNum || this.phone)
      } else {
        await this.onSendCode(this.phoneNum || this.phone)
      }
    }
    this.easyPayConfirmCount = 6
    let url = decodeURIComponent(window.location.href)
    let qrcode = this.$route.query.qrcode
    let state = this.$route.query.state
    let paypalTokenId =
      this.$route.query.approval_token_id || this.$route.query.token
    // let paypalSessionId = this.$route.query.approval_session_id
    console.log(url)
    let easypayid = getQueryString("transaction_key")
    let expirationdate = getQueryString("expiration_date")
    let easyPayTradeNo = getQueryString("id")
    if (expirationdate) {
      this.saveEasyPayCardConfirm(easypayid, easyPayTradeNo)
      return
    }
    if (state && state.includes("success") && this.payprexxId) {
      let devicecode = state.split("success")[1]
      this.$store.commit("cabinet", { cabinetID: devicecode })
      this.count = 0
      this.payprexxConfirm(this.payprexxId)
      this.$store.commit("payprexxId", "")
      return
    }
    if (state && state === "failed" && this.payprexxId) {
      this.$toast(i18n.pay_error)
      return
    }
    if (paypalTokenId) {
      this.onPaypalConfirm(this.paymentId, paypalTokenId)
      return
    }
    if (url.indexOf("qr_code_session_id") > -1) {
      if (url.indexOf("#/?") > -1) {
        // this.returnApp = url.split('#/?')[0].split('/?')[1]
        this.$store.commit("returnAppUrl", url.split("#/?")[0].split("/?")[1])
      }
      if (url.split("qr_code_session_id=")[1]) {
        this.$store.commit(
          "qrCodeSessionId",
          url.split("qr_code_session_id=")[1]
        )
      }
      let qrCode1 = ""
      if (url.indexOf("qrcode") > -1) {
        qrCode1 = url.split("qrcode=")[1].split("?")[0]
      }
      this.$store.commit("qrcode", qrCode1)
      this.onCode()
      return
    }
    if (qrcode || this.qrcode) {
      this.$store.commit("qrcode", qrcode || this.qrcode)
      this.onCode()
    }
    const payId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    )
    console.log("created===", payId)
    if (payId) {
      this.confirmSetup(payId)
    }
  }
}
</script>

<style lang="less" scoped>
#buttonDiv {
  opacity: 0;
  position: relative;
  z-index: 9;
}

.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.buttonDiv {
  position: relative;
  // z-index: 99;
  width: 100%;
  margin: 30px 0;
  // opacity: 0;
}
.linkblock {
  margin-top: 60px;
  a {
    display: block;
    padding: 10px 20px;
    color: #000;
    font-size: 21px;
    text-align: center;
    font-weight: bold;
  }
}

.language {
  position: relative;
  // top: 40px;
  // right: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 40px 40px 0px;

  &-label {
    width: 60px;
    height: 50px;
    display: block;
    border: solid 1px #23c8ee;
    padding: 10px;
    font-size: 39px;
    border-radius: 20px;
    line-height: 50px;
    text-align: center;
  }

  &-tip {
    font-size: 13px;
    margin-top: 10px;
  }
}

.googlewarp {
  position: absolute;
  top: 0;
  // z-index: 1;
  width: 100%;
  background: #ebebeb;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 15px 0;

  img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }
  .right {
    width: 50px;
  }
}

.select {
  color: #000 !important;
  background-color: #00fc00 !important;
  border: none !important;
}

.selectd {
  border: solid 1px #00fc00 !important;
}

.mb-8 {
  margin-bottom: 60px;
}

.minheight-5 {
  min-height: 90px;
}
.logout {
  border: solid 1px transparent;
  background-image: linear-gradient(180deg, #060d11 0%, #060d11 0%),
    linear-gradient(to right, #028bd2, #23c8ee);
  background-clip: content-box, border-box;
  border-radius: 100px;

  span {
    display: block;
    margin: 10px;
  }
}

.logo {
  display: block;
  margin: 40px auto 0;
  width: 60%;
  border-radius: 30px;
}

.home-body {
  padding: 40px 40px 0px;
  background: #fff;
  color: #000;
  border-top-right-radius: 80px;
  border-top-left-radius: 80px;

  .title {
    font-family: "Montserrat-SemiBold";
    text-align: center;
    font-size: 40px;
    margin: 40px 0 120px;
  }

  .login-method {
    width: 100%;
    background: #ebebeb;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    padding: 15px 0;
    margin: 30px 0;
    border: none;
    img {
      width: 50px;
      height: 50px;
      margin-left: 20px;
    }
    .right {
      width: 50px;
    }
  }

  .options {
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    margin: 32px 0 60px;
  }

  .shopdetail {
    border: solid 1px transparent;
    // background-image: linear-gradient(180deg, #060D11 0%, #060D11 0%),
    //   linear-gradient(to right, #028BD2, #23C8EE);
    // background-clip: content-box, border-box;
    // background: #ebebeb;
    border-radius: 100px;
    color: #000;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 20px 0;
    }
    img {
      width: 45px;
      height: 45px;
      margin-left: 20px;
    }
    .pricingtip {
      display: block;
      background: #00fc00;
      padding: 5px 15px;
      margin-left: 20px;
      border-radius: 100px;
    }

    .pricing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000;
      font-size: 20px;

      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
  .loginbtn {
    border: none;
    border-radius: 100px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    width: 100%;
    background: #ebebeb;
    .userinfo {
      display: flex;
      align-items: center;
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .logout {
      border: solid 1px #23c8ee;
      background: #ebebeb;
      // background-image: linear-gradient(180deg, #060D11 0%, #060D11 0%),
      //   linear-gradient(to right, #028BD2, #23C8EE);
      // background-clip: content-box, border-box;
      border-radius: 100px;

      span {
        display: block;
        margin: 10px;
      }
    }
  }

  .rentbtn {
    width: 100%;
    background: linear-gradient(to right, #028bd2, #23c8ee);
    border-radius: 100px;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: "Montserrat-SemiBold";
    font-size: 36px;
    font-weight: 500;
    max-height: 108px;
    margin: 40px 0 0;
  }

  .agreen {
    color: #000;
    text-align: center;
    margin: 20px 0 20px;
    font-size: 21px;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .localtip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0 20px;
    padding: 20px;
    border: solid 1px #028bd2;
    border-radius: 100px;
    background: #011728;

    img {
      width: 60px;
      margin-right: 20px;
    }

    p {
      margin: 0;
    }

    .tip1 {
      font-size: 24px;
    }

    .tip2 {
      font-size: 16px;
    }
  }

  .mt-4 {
    margin-top: 40px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;

  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px;
  }

  .agreen {
    color: #000;
    text-align: center;
    margin: 20px 0 20px;
    font-size: 21px;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto 40px;
  }

  .loginoptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 0;

    &-item {
      border: solid 1px #ebebeb;
      border-radius: 20px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 25px 0 0;

      img {
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .phonelogin {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;

    img {
      width: 60px;
      height: 60px;
      transform: rotateZ(180deg);
    }

    input {
      flex: 1;
      font-size: medium;
      border: none;
      outline: none;
      color: #000;
    }

    input::placeholder {
      font-size: 18px;
    }

    .captcha {
      width: 180px;
    }

    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #000;
    }

    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }

  .facebookbtn {
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .goolebtn {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #ebebeb;
    color: #000;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}

.couponipt {
  border: solid 1px #c8c8c8;
  width: 80%;
  display: block;
  margin: 50px auto 40px;
  padding: 20px;
  border-radius: 20px;
  color: #000;
}

.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}

.creditcard-btn {
  margin: 48px;
}

.addsign {
  float: left;
  background-color: #fff;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px;
  width: 18%;
  height: 100%;
  border-radius: 20px;
  margin: 33px 0 20px 20px;

  span {
    display: block;
    background-color: #dddddd;
    color: #fff;
    font-size: 85px;
    text-align: center;
    border-radius: 20px;
  }
}

.scroll-y {
  overflow-y: scroll;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  padding: 20px;
}

.popup-card-list {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 15px 25px;
  margin: 20px;
  border: solid 1px #7b7b7b;

  .popup-card-item {
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 36px;

    img {
      width: 60px;
      margin-right: 20px;
    }
  }

  p {
    margin: 0;
  }

  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00904a;
    font-weight: 500;

    .pBrand {
      font-size: 40px;
      margin-right: 10px;
    }
  }

  .deleteicon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
</style>
