export const STRIPE_PAY_OF_CONFIRM = 'cdb/payment/stripe/prepayOfConfirm'
export const STRIPE_RECHARGE_OF_CONFIRM = 'cdb/payment/stripe/rechargeOfConfirm'
export const STRIPE_PAY_CONFIRM = 'cdb/payment/stripe/payConfirm'

export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay' // 原先的预授权租借
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge' // 原先的订单付款
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_PAYORDER_SPTOKEN = 'cdb/payment/stripe/multicurrency/payOrder/stripToken' // 目前使用的订单付款
export const STRIPE_PAYORDER = 'cdb/payment/stripe/multicurrency/payOrder' // 信用卡订单付款
export const STRIPE_RECHARGE_WALLET = 'cdb/payment/stripe/multicurrency/rechargeWallet' // 创建钱包充值订单

export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_CLIENTSECRET = 'cdb/payment/stripe/multicurrency/clientSecret' // 获取客户秘钥
export const STRIPE_PREAUTH_AUTO_CONFIRM1 = 'cdb/payment/stripe/authConfirm'
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PAYCONFIRM = 'cdb/payment/stripe/multicurrency/payConfirm/payment' // 确认订单-旧

export const COUPON_SEND = 'cdb/mine/coupon/send' // 换取优惠券

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'

export const WECHAT_MP_PRE_PAY = 'cdb/payment/wechat_mp/prepay'
export const ALIPAY_PRE_PAY = 'cdb/payment/alipay/prepay'

export const WECHAT_MP_RECHARGE = 'cdb/payment/wechat_mp/recharge'

export const YEDPAY_RECHARGE = 'cdb/payment/yedpay/recharge'
export const YEDPAY_PREPAY = 'cdb/payment/yedpay/prepay'
export const YEDPAY_PAY_CONFIRM = 'cdb/payment/yedpay/payConfirm'
export const YEDPAY_PREPAY_RENT = 'cdb/payment/yedpay/rent'
export const YEDPAY_PAY_ORDER = 'cdb/payment/yedpay/payOrder'
export const VIP_ORDER = 'cdb/payment/yedpay/vipOrder' // yedpay购买vip
export const YEDPAY_PAY_COUPON = 'cdb/payment/yedpay/buyCoupon'

export const TIXIAN_YAJIN = 'cdb/mine/tixian/yajin'
export const TIXIAN_APPLY = 'cdb/mine/yajin/apply'
export const CREATE_VIP_ORDER = 'cdb/cabinet/createorderByVip'
export const CDY_REMART_HOME = 'cdb/payment/cdymart/home'
// datatrans 支付
export const DATATRANS_PREAUTH = 'cdb/payment/datatrans/multiCurrency/preAuthOrder' // datatrans创建预授权订单
export const DATATRANS_MV_RECHARGE = 'cdb/payment/datatrans/multiCurrency/mv/recharge' // mv钱包充值
export const DATATRANS_SAVECARD = 'cdb/payment/datatrans/multiCurrency/savePaymentMethord' // datatrans保存支付方式
export const DATATRANS_SAVECARD_CONFIRM = 'cdb/payment/datatrans/multiCurrency/savePaymentMethord/confirm' // datatrans保存支付方式确认
// EasyPay
export const EASYPAY_PREAUTH = 'cdb/payment/easyPay/preAuthOrder' // 预授权订单
export const EASYPAY_PREAUTH_CONFIRM = 'cdb/payment/easyPay/payment/confirm' // 预授权订单确认
export const EASYPAY_RECHARGE = 'cdb/payment/easyPay/recharge' // 钱包充值
export const EASYPAY_SAVECARD = 'cdb/payment/easyPay/savePaymentMethord' // 保存支付方式
export const EASYPAY_SAVECARDCONFIRM = 'cdb/payment/easyPay/savePaymentMethord/confirm' // 保存支付方式确认
// 丹麦支付
export const USER_CARD = 'cdb/user/card' // 卡列表
export const WALLET_LIST = 'cdb/user/wallet' // 钱包列表
export const SAVE_CARD = 'cdb/payment/quickpay/multiCurrency/saveCard' // 保存卡
export const QUICK_CREATE = 'cdb/payment/quickpay/multiCurrency/createOrder' // 创建预授权订单
export const WALLET_RECHARGE = 'cdb/payment/quickpay/multiCurrency/recharge' // 钱包充值
export const PAY_ORDER = 'cdb/payment/quickpay/multiCurrency/payOrder' // 订单付款
export const DEPOSIT_TO_RENT = 'cdb/cabinet/useDepositToRent' // 押金租借
export const BALANCE_TO_RENT = 'cdb/cabinet/useBalanceToRent' // 用户余额抵扣押金并租借
export const SAVEDCARD_CONFIRM = 'cdb/payment/quickpay/multiCurrency/savedCard/confirm' // 确认保存卡是否已授权
export const QUICKPAY_CONFIRM = 'cdb/payment/quickpay/multiCurrency/payment/confirm' // 确认订单是否授权或支付
export const STRIPE_SAVE_CARD = 'cdb/payment/stripe/multicurrency/saveCardOfIntent' // stripe保存卡
export const STRIPE_SAVE_CARD_CONFIRM = 'cdb/payment/stripe/multicurrency/saveCardConfirm' // stripe保存卡确认
export const REMOVE_CARD = 'cdb/user/card/remove' // 删除卡
export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
export const SAVED_CARD_RENT = 'cdb/payment/stripe/multicurrency/preauthByCard/payment' // 已保存卡租借
export const SAVE_PAYMENT_METHORD = 'cdb/payment/payprexx/savePaymentMethord'
export const SAVE_PAYMENT_CONFIRM = 'cdb/payment/payprexx/savePaymentMethord/confirm'
export const SAVE_CARD_TO_RENT = 'cdb/cabinet/savedCardToRent'
export const PAYPAL_SAVECARD = 'cdb/payment/paypal/multicurrency/setupToken' // paypal保存卡
export const PAYPAL_SAVECARD_CONFIRM =
  'cdb/payment/paypal/multicurrency/saveToken' // paypal确认保存卡
