var datatransScript
export default {
  methods: {
    loadDataTransScript (success = () => {}) {
      if (!datatransScript) {
        datatransScript = window.document.createElement('script')
        datatransScript.src = 'https://pay.datatrans.com/upp/payment/js/datatrans-2.0.0.js'
        datatransScript.onload = () => {
          console.log('loadDataTransScript成功===========')
          window.datatrans = Datatrans
          success && success()
        }
        var head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
        head.insertBefore(datatransScript, head.firstChild)
      } else {
        console.log('DataTrans已加载')
        window.datatrans = Datatrans
        success && success()
      }
    }
  }
}
