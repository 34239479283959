<template>
  <div>
    <div class="map-container">
      <div class="google-map" id="google-map"></div>
    </div>
    <div v-if="shopInfo && shopInfo.shopName" class="shop-info">
      <img class="iconclose" src="../../assets/imgs/icon_close.png" @click="onClose">
      <h3>{{ shopInfo.shopName }}</h3>
      <h4>{{ shopInfo.shopAddress || '---' }}</h4>
      <div class="shop-info-rent">
        <div>
          <span class="label">{{ i18n.rent }}</span>
          <span class="value">{{ shopInfo.batteryNum || 0 }}</span>
        </div>
        <div>
          <span class="label">{{ i18n.return }}</span>
          <span class="value">{{ shopInfo.canReturnNum || 0 }}</span>
        </div>
      </div>
      <div class="shop-info-here" @click="goGoogleMap">{{ i18n.here }}</div>
    </div>
  </div>
</template>
<script>
import { SHOP_LIST } from '../../apis/cabinet'
import logoIcon from '../../assets/imgs/icon_localeicon.png'
import usericon from '../../assets/imgs/location.png'
let gMapContext
let infoWindow
export default {
  data () {
    return {
      latitude: '46.950139', // 46.950139
      longitude: '7.44716', // 7.44716
      markerList: [],
      zoomLevel: 5,
      shopInfo: {}
    }
  },
  computed: {
    i18n () {
      return this.$t('nearby')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  methods: {
    clickMarker (val) {
      console.log('clickMarkeritem====', val)
      this.shopInfo = val
    },
    onClose () {
      console.log('关闭===')
      this.shopInfo = {}
    },
    goGoogleMap () {
      window.location.href = `https://www.google.com/maps/dir/${this.latitude},${this.longitude}/${this.shopInfo.latitude},${this.shopInfo.longitude}`
    },
    getMapMarkListOption () {
      this.$post(SHOP_LIST, {
        coordType: 'WGS-84',
        zoomLevel: this.zoomLevel,
        lat: this.latitude,
        lng: this.longitude
      }, resp => {
        // console.log(resp)
        this.markerList = resp.list
        this.setMarkerList()
      }, err => {
        console.log(err)
      })
    },
    initMap () {
      let center = { lat: Number(this.latitude), lng: Number(this.longitude) }
      let that = this
      gMapContext = new google.maps.Map(document.getElementById('google-map'), {
        center: center,
        zoom: 15,
        mapTypeId: 'roadmap'
      })
      gMapContext.addListener('dragend', () => {
        console.log('getCenter', gMapContext.getCenter())
        let latlng = gMapContext.getCenter()
        that.latitude = latlng.lat()
        that.longitude = latlng.lng()
        that.getMapMarkListOption()
      })
      infoWindow = new google.maps.InfoWindow()
    },
    setMarkerList () {
      console.log('标记设置====')
      let that = this
      if (this.oldMarkerList && this.oldMarkerList.length > 0) {
        this.oldMarkerList.map(item => {
          item.setMap(null)
          item = null
        })
      }
      if (this.markerList && this.markerList.length > 0) {
        this.oldMarkerList = this.markerList.map(obj => {
          let marker = new google.maps.Marker({
            position: { lat: parseFloat(obj.latitude), lng: parseFloat(obj.longitude) },
            map: gMapContext,
            optimized: false,
            icon: {
              url: logoIcon,
              scaledSize: new google.maps.Size(30, 40),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(10, 30)
            }
          })
          marker.addListener('click', () => {
            infoWindow.close()
            infoWindow.setContent(obj.shopName)
            infoWindow.open(marker.getMap(), marker)
            that.clickMarker(obj)
          })
          return marker
        })
      }
      let userimg = new google.maps.Marker({
        position: { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) },
        map: gMapContext,
        icon: {
          url: usericon,
          scaledSize: new google.maps.Size(30, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 30)
        }
      })
      this.oldMarkerList.push(userimg)
    },
    createGScript () {
      let gscript = document.createElement('script')
      // let that = this
      // console.log('asdfasdfsafdsfasdfsdfs')
      gscript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAaoltBpcY531FJU8ITjdqKiHA9aRfAQ7I&callback=initMap'
      gscript.async = true
      let head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
      head.insertBefore(gscript, head.firstChild)
    },
    getLocation () {
      if (navigator.geolocation) {
        let that = this
        navigator.geolocation.getCurrentPosition(
          function (res) {
            console.log('浏览器定位', res)
            that.latitude = res.coords.latitude
            that.longitude = res.coords.longitude
            that.createGScript()
            that.getMapMarkListOption()
          },
          err => {
            console.log('geolocation fail', err)
          }
        )
      } else {
        this.$confirm({
          title: this.i18n.msg_fail_title,
          confirmText: this.i18nMsg.confirm,
          content: this.i18nMsg.browsernotsupport,
          showCancel: false
        })
      }
    }
  },
  created () {
    window.initMap = this.initMap
    this.getLocation()
  },
  mounted () {
  }
}
</script>
<style lang="less" scoped>
.map-container{
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    .google-map{
      height: 100%;
    }
  }
  .shop-info{
    position: absolute;
    position: relative;
    bottom: 7%;
    left: 50%;
    transform: translateX(-55%);
    border-radius: 20px;
    background: #fff;
    color: #000;
    z-index: 10;
    width: 70%;
    padding: 0 10px 20px;
    .iconclose{
      position: absolute;
      top: 15px;
      right: 20px;
      width: 40px;
    }
    &-rent{
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 30px;
      .label{
        color: #fff;
        background: rgb(116, 199, 255);
        padding: 7px;
        border-radius: 40px;
        margin-right: 15px;
      }
    }
    &-here{
      background: rgb(116, 199, 255);
      color: #fff;
      border-radius: 50px;
      padding: 10px 0;
      text-align: center;
    }
  }
</style>
