<template>
  <div class="mask" @click="onClose()">
    <div class="mask-main" @click.stop>
      <div class="appleline"></div>
      <div class="title">
        <img src="../../../assets/imgs/icon_detail_green.png" alt="">
        <span>{{ i18n.preauthortitle }}</span>
      </div>
      <p>{{ i18n.tip1 }}</p>
      <p class="text-green">{{ i18n.tip2 }}</p>
      <p>{{ i18n.tip3 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  watch: {
  },
  computed: {
    i18n () {
      return this.$t('preauth')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onClose () {
      this.$emit('close', false)
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;
  .mask-main{
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px 60px 150px;
  }
  .appleline{
    background-color: #EBEBEB;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto;
  }
  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    font-size: 36px;
    font-family: 'Montserrat-SemiBold';
    font-weight: bold;
    img{
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
  p{
    color: #666666;
    font-size: 24px;
    padding-left: 20px;
  }
  .text-green{
    color: #1bb6e6;
  }
}
</style>
