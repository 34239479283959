<template>
  <div class="page" :style="{'padding-top': safeArea.top + 'px'}">
    <div class="page-nav">
      <slot name="nav">
      </slot>
    </div>
    <div class="page-head">
      <slot name="head">
        <!-- <div class="back"></div> -->
        <img class="logo" src="../../assets/imgs/logo.png" alt="" />
        <!-- <div class="righticon">
          <span @click="showLangSelect">{{ locale.label }}</span>
        </div> -->
      </slot>
    </div>
    <div class="page-body">
      <slot></slot>
    </div>
    <div class="page-foot" v-if="hasFoot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    back: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Boolean,
      default: true
    },
    help: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['safeArea', 'headLogo']),
    ...mapGetters(['locale']),
    hasHead () {
      return this.$slots.head || this.title
    },
    hasFoot () {
      return this.$slots.foot
    }
  },
  methods: {
    showLangSelect () {
      this.$store.commit('isLangView', true)
    },
    goRouter (path) {
      this.$router.push(path)
    },
    onBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
.page{
  &-head{
    // position: fixed;
    // z-index: 99;
    // top: 0;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 20px 0 0;
    padding: 30px 80px;
    .righticon{
      width: 25%;
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgb(151, 151, 151);
      border-radius: 20px;
      background-color: #000d1e;
      color: #FFFFFF;
      &::after{
        content: '';
        width: 0;
        margin-left: 10px;
        border: 10px solid;
        border-color: #fff transparent transparent transparent;
      }
    }
    .menu {
      width: 60px;
      margin-right: 32px;
    }
    .back {
      width: 23%;
      margin-left: 32px;
    }
    .logo{
      width: 172px;
      height: 88px;
      border-radius: 20px;
    }
    .twintlogo{
      width: 210px;
      height: 88px;
      border-radius: 20px;
    }
  }
  // &-head+&-body{
  //   padding-top: 88px;
  // }
  &-body{
    height: 100%;
    min-height: 100%;
  }
  &-foot{
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
.page-head-default .home {
  height: 40px;
  width: 40px;
  // position: absolute;
  left: calc(100vw - 72px);
  right: 32px;
  transform: scale(1.4);
  position: fixed;
}
</style>
