// 法语
export default {
  home: {
    price_info: '{mianfei} minutes gratuits, maximum par jour {currencyName}{fengding}, dépôt {currencyName}{yajin}, dépôt de location gratuit',
    submit: 'Recharger',
    borrow: 'Emprunter',
    msg_rentcount: 'Vous avez actuellement {zujieNum} commandes qui ne sont pas fermées',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    msg_fail_title: 'Erreur',
    msg_fail_content: 'La location a échoué, veuillez réessayer',
    gomap: 'Voir les station à proximité',
    offline: 'L\'appareil {devicecode} est hors ligne, veuillez contacter les personnes présentes'
  },
  loginborrow: {
    complaint: 'Plaintes',
    paynow: 'Immediate payment',
    claimsettlement: 'Réclamations et litiges',
    daili_error: `L'agent actuel ne prend pas en charge ce mode de paiement, veuillez changer d'agent`,
    name: "Veuillez saisir le nom d'utilisateur",
    msg_email_err: `Erreur de format d'e-mail`,
    email: `Veuillez saisir l'adresse e-mail`,
    priceInfo: '{currency} {jifei} par {jifeiDanwei} minutes',
    powerbanktitle: 'LIBÈRE TON POWER BANK',
    loginwith: 'Connectez-vous avec',
    msg_fail_title: 'Erreur',
    msg_fail_user: 'Erreur lors de l\'obtention des informations utilisateur. Voulez-vous réessayer ?',
    msg_rentcount: 'Vous avez actuellement {zujieNum} commandes qui ne sont pas fermées',
    msg_fail_support: 'Le système n\'est pas pris en charge',
    msg_fail_confirm: 'Confirmer',
    msg_fail_retry: 'Réessayer',
    msg_fail_cancel: 'Annuler',
    msg_fail_order: 'Échec de la création de la commande de location',
    msg_fail_paymod: 'Échec de l\'obtention du mode de paiement',
    msg_fail_login: 'Échec de la connexion',
    Apple: 'Apple',
    otheropt: 'Autres options',
    selectmethod: 'Sélectionnez votre mode de paiement',
    paymentdetail: 'Détails du mode de paiement',
    couponcode: 'Code promo',
    rent: 'Confirmer le paiement',
    pricing: 'Voir les prix',
    phone: 'Téléphone',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Se connecter avec Facebook',
    logingg: 'Se connecter avec Google',
    loginapple: 'Se connecter avec Apple',
    loginphone: 'Se connecter avec Téléphone',
    captcha_code: 'Code',
    phonenumber: 'Entrez votre numéro de téléphone portable ',
    msg_fail_code: 'Veuillez entrer le code de vérification',
    msg_fail_phone: 'Veuillez entrer votre numéro de téléphone',
    logout: 'Quitter le compte',
    savenow: 'Économisez maintenant',
    delete: 'Supprimer',
    addcard: 'Entrez les détails de la carte',
    msg_fail_content: 'La location a échoué, veuillez réessayer',
    title: 'Veuillez confirmer l\'autorisation',
    policy: 'Politique.',
    term: ' Conditions générales d\'utilisation.',
    and: ' et les',
    privacy: 'Politique de confidentialité',
    tips: 'En continuant, vous acceptez notre ',
    cancel: 'ANNULER',
    agree: 'ACCEPTER',
    openinbrowser: 'Veuillez ouvrir dans votre navigateur',
    localtip1: 'Renvoyez la batterie à n\'importe quelle station LotusCharge',
    pay_error: 'Le paiement a échoué, veuillez réessayer',
    localtip2: 'Pour trouver une station à proximité, consultez la carte ou scannez le code QR sur le power bank'
  },
  preauth: {
    preauthortitle: 'À propos de la pré-autorisation',
    tip1: 'Lorsque vous commencez une location, une pré-autorisation est placée sur votre mode de paiement. Cela permet simplement de s\'assurer qu\'il y a suffisamment de fonds disponibles pour payer votre location.',
    tip2: 'Votre banque peut vous envoyer une notification mais il ne s\'agit pas d\'un prélèvement : seul le prix de votre location sera facturé.',
    tip3: 'Selon votre banque, il peut s\'écouler quelques heures à quelques jours avant que le montant ne soit ajusté sur vos relevés, c\'est normal.'
  },
  rentcost: {
    rentcosttitle: 'Combien coûte la location ?',
    price1: '{currency} {jifei} par {jifeiDanwei} minutes',
    price2: '{currency} {fengding} par jour',
    tip1: "If you do not return the power bank within {overtime} hours you will be charged a non-return fee.",
    tip2: "One day rental is 24 hours from the time the power bank is hired.",
    tip3: "We will charge {yajin} {currency} as a security deposit that will be returned once the battery is returned to the station.",
    tip4: "Some venues might offer some free hours or discounts. Enjoy!",
    Days: 'Jours',
    Hours: 'Heures',
    Minutes: 'Minutes',
    free: '{mianfei} minutes free',
    willcharged: 'Vous serez facturé {currency} {money}'
  },
  logincode: {
    login: 'Connexion',
    retry: 'Renvoyer'
  },
  agreenconfirm: {
    title: 'Veuillez confirmer l\'autorisation',
    policy: 'Politique.',
    term: 'Conditions générales d\'utilisation de LotusCharge',
    and: 'et',
    privacy: 'Politique de confidentialité.',
    tips: 'Je suis d\'accord avec ',
    cancel: 'ANNULER',
    agree: 'ACCEPTER'
  },
  login: {
    phonenumber: 'Numéro de téléphone',
    verificationcode: 'Code de vérification',
    Rentpowerbank: 'Louer une batterie externe',
    Rentpowerbanktip: 'Entrez votre numéro de téléphone portable pour commencer',
    phone_number: 'Veuillez entrer un numéro de téléphone portable à {0} chiffres ',
    captcha_code: 'Code',
    send_code: 'Envoyer le code',
    login: 'Connexion',
    agree: 'En cliquant sur Connexion, vous acceptez ',
    readme: `Contrat d'utilisateur`,
    msg_fail_login: 'Échec de la connexion',
    msg_success_login: 'Connexion réussie',
    msg_success_login_code: 'Reçu avec succès, veuillez télécharger l\'APP pour vous connecter et utiliser',
    msg_fail_user: 'Erreur lors de l\'obtention des informations utilisateur. Voulez-vous réessayer ?',
    no_sms: 'Pas de message texte reçu',
    retry: 'Renvoyer',
    receive: 'Postuler',
    invite_pwd: 'Code d\'invitation',
    vip_agreen_tip: 'En continuant, vous indiquez que vous acceptez notre',
    vip_agreen_service: 'Conditions d\'utilisation',
    vip_agreen_and: 'et',
    vip_agreen_policy: 'Politique de confidentialité'
  },
  msg: {
    issuccesspreauth: 'Veuillez confirmer la réception du message d\'autorisation de MB Way et appuyez sur terminer l\'autorisation',
    save_success: 'Sauvegarder le succès',
    none_code: 'Le code n\'existe pas',
    sms_fail: 'Échec de l\'envoi du SMS',
    unlogin: 'Vous ne vous êtes pas encore connecté, veuillez vous connecter',
    msg_fail_title: 'Avertissement',
    deletecard: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
    loading: 'Chargement...',
    title: 'Conseils',
    success: 'Opération réussie',
    error: 'Erreur inconnue, veuillez réessayer plus tard.',
    roleerror: 'Permissions insuffisantes, veuillez consulter l\'administrateur',
    neterror: 'Échec de la connexion au réseau. Veuillez réessayer plus tard',
    confirm: 'Confirmer',
    retry: 'Réessayer',
    cancel: 'Annuler',
    msg_logout: 'Êtes-vous sûr de vouloir quitter le compte ?',
    vieworder: 'Voir la commande',
    addorselectcard: 'Veuillez ajouter ou sélectionner un forfait carte pour continuer',
    success_payment: 'Paiement effectué',
    fail_payment: 'Échec du paiement',
    keyword: 'mot-clé',
    unpaid_order: 'Il y a des commandes impayées',
    go_pay: 'Aller payer',
    no_more: 'plus rien',
    noshop: 'Pas de marchands',
    msg_fail_code: 'Veuillez entrer le code de vérification',
    msg_fail_phone: 'Veuillez entrer votre numéro de téléphone',
    exceedorder: 'Vous avez déjà une commande en cours de location, veuillez la retourner avant de continuer',
    msg_confirm_preAuth: 'Confirmer la préautorisation ?',
    msg_confirm_preAuth_fail: "Une erreur s'est produite lors du paiement. Veuillez payer à nouveau et confirmer à nouveau la préautorisation.",
    browsernotsupport: 'Votre navigateur ne supporte pas le positionnement',
    jumpfail: 'Le saut automatique a échoué, appuyez manuellement sur le bouton pour continuer'
  },
  result: {
    title: 'Location confirmée',
    tips: 'Pour voir les commandes et les autres emplacements LotusCharge, veuillez télécharger l\'application.',
    download: 'TÉLÉCHARGER',
    msg_result_loading: 'Le résultat du paiement est en cours de confirmation...',
    msg_tips_loading: 'La batterie portable est en train de sortir, veuillez patienter...'
  },
  result_msg: {
    loading: 'Chargement...',
    refresh: 'Actualiser'
  },
  nearby: {
    rent: 'Prêt',
    return: 'Restitution',
    here: 'Allez ici'
  }
}
