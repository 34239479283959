// 葡萄牙语
export default {
  home: {
    price_info: '{mianfei} minutos grátis, máximo por dia {currencyName}{fengding}, depósito {currencyName}{yajin}, aluguel grátis',
    submit: 'Recarregar',
    borrow: 'Emprestar',
    msg_rentcount: 'Você tem atualmente {zujieNum} pedidos que não estão fechados',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    msg_fail_title: 'Erro',
    msg_fail_content: 'Falha no aluguel, por favor tente novamente',
    gomap: 'Ver estação próxima',
    offline: 'Dispositivo {devicecode} está offline, por favor contacte o pessoal presente'
  },
  loginborrow: {
    complaint: 'Livro reclamações',
    paynow: 'Immediate payment',
    claimsettlement: 'Reclamações e resolução de litígios',
    daili_error: 'O agente atual não oferece suporte a esta forma de pagamento. Troque de agente',
    name: 'Por favor insira o nome de usuário',
    msg_email_err: 'Erro no formato do e-mail',
    email: 'Por favor insira o endereço de e-mail',
    priceInfo: '{currency} {jifei} per {jifeiDanwei} minutes',
    powerbanktitle: 'Solte o seu banco de energia',
    loginwith: 'Entrar com',
    msg_fail_title: 'Erro',
    msg_fail_user: 'Erro ao obter informações do usuário. Você quer tentar novamente?',
    msg_rentcount: 'Você tem atualmente {zujieNum} pedidos que não estão fechados',
    msg_fail_support: 'O sistema não é suportado',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Tentar novamente',
    msg_fail_cancel: 'Cancelar',
    msg_fail_order: 'Falha ao criar ordem de locação',
    msg_fail_paymod: 'Falha ao obter método de pagamento',
    msg_fail_login: 'Falha no login',
    Apple: 'Apple',
    otheropt: 'Outras opções',
    selectmethod: 'Selecione seu método de pagamento',
    paymentdetail: 'Detalhes do método de pagamento',
    couponcode: 'Código de cupom',
    rent: 'Confirmar Pagamento',
    pricing: 'Ver preços',
    phone: 'Telefone',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Entrar com o Facebook',
    logingg: 'Entrar com o Google',
    loginapple: 'Entrar com o Apple',
    loginphone: 'Entrar com o Telefone',
    captcha_code: 'Código',
    phonenumber: 'Digite seu número de telefone',
    msg_fail_code: 'Por favor, insira o código de verificação',
    msg_fail_phone: 'Por favor, insira seu número de telefone',
    logout: 'Sair da conta',
    savenow: 'Salvar agora',
    delete: 'Deletar',
    addcard: 'Inserir detalhes do cartão',
    msg_fail_content: 'Falha no aluguel, por favor tente novamente',
    title: 'Por favor, confirme a autorização',
    policy: 'Política.',
    term: ' Termos e Condições.',
    and: 'e',
    privacy: 'Política de Privacidade ',
    tips: 'Ao continuar, você concorda com nossa ',
    cancel: 'CANCELAR',
    agree: 'CONCORDAR',
    openinbrowser: 'Por favor, abra no navegador',
    localtip1: 'DEVOLVA A BATERIA A QUALQUER ESTAÇÃO DE ENERGIA LotusCharge',
    pay_error: 'Falha no pagamento, tente novamente',
    localtip2: 'Para localizar uma estação próxima, consulte o mapa ou escaneie o QR no Power Bank.'
  },
  preauth: {
    preauthortitle: 'Sobre a pré-autorização',
    tip1: 'Ao iniciar um aluguel, uma pré-autorização é feita no seu método de pagamento. Isso é apenas para garantir que há fundos suficientes disponíveis para pagar pelo seu aluguel.',
    tip2: 'Seu banco pode enviar uma notificação, mas não é uma cobrança: apenas o preço do seu aluguel será cobrado.',
    tip3: 'Dependendo do seu banco, pode levar algumas horas até alguns dias para o valor ser ajustado em seus extratos, isso é normal.'
  },
  rentcost: {
    rentcosttitle: 'Quanto custa o aluguel?',
    price1: '{currency} {jifei} per {jifeiDanwei} minutes',
    price2: '{currency} {fengding} per day',
    tip1: "If you do not return the power bank within {overtime} hours you will be charged a non-return fee.",
    tip2: "One day rental is 24 hours from the time the power bank is hired.",
    tip3: "We will charge {yajin} {currency} as a security deposit that will be returned once the battery is returned to the station.",
    tip4: "Some venues might offer some free hours or discounts. Enjoy!",
    Days: 'Dias',
    Hours: 'Horas',
    Minutes: 'Minutos',
    free: '{mianfei} minutes free',
    willcharged: 'You will be charged {currency} {money}'
  },
  logincode: {
    login: 'Login',
    retry: 'Reenviar'
  },
  agreenconfirm: {
    title: 'Por favor, confirme a autorização',
    policy: 'Política.',
    term: 'Termos & Condições da LotusCharge',
    and: 'e',
    privacy: 'Política de Privacidade.',
    tips: 'Eu concordo com ',
    cancel: 'CANCELAR',
    agree: 'CONCORDAR'
  },
  login: {
    phonenumber: 'Número de telefone',
    verificationcode: 'Código de verificação',
    Rentpowerbank: 'Alugar um power bank',
    Rentpowerbanktip: 'Digite seu número de celular para começar',
    phone_number: 'Por favor, insira um número de celular com {0} dígitos',
    captcha_code: 'Código',
    send_code: 'Enviar Código',
    login: 'Login',
    agree: 'Ao clicar em Login, você concorda com ',
    readme: `Contrato do Usuário`,
    msg_fail_login: 'Falha no login',
    msg_success_login: 'Login bem-sucedido',
    msg_success_login_code: 'Recebido com sucesso, por favor baixe o APP para fazer login e usar',
    msg_fail_user: 'Erro ao obter informações do usuário. Você quer tentar novamente?',
    no_sms: 'Não recebeu mensagem de texto',
    retry: 'Reenviar',
    receive: 'Aplicar',
    invite_pwd: 'Código de convite',
    vip_agreen_tip: 'Ao continuar, você indica que aceita nosso',
    vip_agreen_service: 'Termos de Serviço',
    vip_agreen_and: 'e',
    vip_agreen_policy: 'Política de Privacidade'
  },
  msg: {
    issuccesspreauth: 'Por favor, confirme o recebimento da mensagem de autorização da MB Way e clique em autorização completa',
    save_success: 'Gravar com sucesso',
    none_code: 'Código não existe',
    sms_fail: 'Falha no envio de SMS',
    unlogin: 'Você ainda não fez login, por favor faça login',
    msg_fail_title: 'Aviso',
    deletecard: 'Você tem certeza que deseja deletar este cartão?',
    loading: 'Carregando...',
    title: 'Dicas',
    success: 'Operação bem-sucedida',
    error: 'Erro desconhecido, por favor tente novamente mais tarde.',
    roleerror: 'Permissões insuficientes, por favor consulte o administrador',
    neterror: 'Falha na conexão de rede. Por favor, tente novamente mais tarde',
    confirm: 'Confirmar',
    retry: 'Tentar novamente',
    cancel: 'Cancelar',
    msg_logout: 'Você tem certeza que deseja sair da conta?',
    vieworder: 'Ver ordem',
    addorselectcard: 'Por favor, adicione ou selecione um pacote de cartão para continuar',
    success_payment: 'Pagamento concluído',
    fail_payment: 'Falha no pagamento',
    keyword: 'palavra-chave',
    unpaid_order: 'Existem ordens não pagas',
    go_pay: 'Ir pagar',
    no_more: 'não mais',
    noshop: 'Sem comerciantes',
    msg_fail_code: 'Por favor, insira o código de verificação',
    msg_fail_phone: 'Por favor, insira seu número de telefone',
    exceedorder: 'Você já tem uma ordem sob locação, por favor devolva antes de continuar',
    msg_confirm_preAuth: 'Confirmar pré-autorização?',
    msg_confirm_preAuth_fail: 'Ocorreu um erro no pagamento. Pague novamente e confirme a pré-autorização novamente.',
    browsernotsupport: 'Seu navegador não suporta posicionamento',
    jumpfail: 'Salto automático falhou, clique manualmente no botão para continuar'
  },
  result: {
    title: 'Aluguel confirmado',
    tips: 'Para ver pedidos e outros locais LotusCharge, por favor baixe o App.',
    download: 'BAIXAR',
    msg_result_loading: 'O resultado do pagamento está sendo confirmado...',
    msg_tips_loading: 'A bateria portátil está aparecendo, por favor aguarde pacientemente...'
  },
  result_msg: {
    loading: 'Carregando...',
    refresh: 'Atualizar'
  },
  nearby: {
    rent: 'Locação',
    return: 'retorno',
    here: 'Vá aqui'
  }
}
