// 瑞士
export default {
  home: {
    price_info: '{mianfei} minutes free, maximum per day  {currencyName}{fengding}, deposit {currencyName}{yajin},  free rental deposit',
    submit: 'Recharge',
    borrow: 'Borrow',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    cancel: 'Cancel',
    confirm: 'Confirm',
    msg_fail_title: 'Error',
    msg_fail_content: 'Rental failed, please try again',
    gomap: 'View nearby station',
    offline: 'Enheten {enhetskod} är offline, vänligen kontakta den närvarande personalen'
  },
  loginborrow: {
    complaint: 'klagomål',
    paynow: 'Immediate payment',
    claimsettlement: 'Krav och tvister',
    daili_error: `L'agente attuale non supporta questo metodo di pagamento, cambia agente`,
    name: 'Inserisci il nome utente',
    msg_email_err: `Errore nel formato dell'e-mail`,
    email: `Inserisci l'indirizzo email`,
    priceInfo: '{jifei} {currency} per {jifeiDanwei} minuti',
    powerbanktitle: 'Rilascia il tuo powerbank',
    loginwith: 'Accedi con',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    msg_fail_support: 'The system is not supported',
    msg_fail_confirm: 'Confirm',
    msg_fail_retry: 'Retry',
    msg_fail_cancel: 'Cancel',
    msg_fail_order: 'Failed to create lease order',
    msg_fail_paymod: 'Failed to obtain payment method',
    msg_fail_login: 'Login failed',
    Apple: 'Apple',
    otheropt: 'Other options',
    selectmethod: 'Select your payment method',
    paymentdetail: 'Payment method details',
    couponcode: 'Coupon code',
    rent: 'Confirm Payment',
    pricing: 'See pricing',
    phone: 'Phone',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Accedi con Facebook',
    logingg: 'Accedi con Google',
    loginapple: 'Accedi con Apple',
    loginphone: 'Accedi con Telefono',
    captcha_code: 'Code',
    phonenumber: 'Enter your phone number',
    msg_fail_code: 'Please enter the verification code',
    msg_fail_phone: 'Please enter your phone number',
    logout: 'Exit account',
    savenow: 'Save Now',
    delete: 'Delete',
    addcard: 'Enter card details',
    msg_fail_content: 'Rental failed, please try again',
    title: 'Please confirm authorisation',
    policy: 'Policy.',
    term: ' Condizioni d\'Uso',
    and: 'e le',
    privacy: 'Politica sulla Privacy',
    tips: 'Continuando, accetti la nostra ',
    cancel: 'CANCEL',
    agree: 'AGREE',
    openinbrowser: 'Öppna den i webbläsaren',
    localtip1: 'Restituisci la batteria a qualsiasi stazione energetica LotusCharge',
    pay_error: 'Pagamento non riuscito, riprova',
    localtip2: 'Per localizzare una stazione nelle vicinanze, consulta la mappa o scansiona il QR sulla power bank.'
  },
  preauth: {
    preauthortitle: 'About the pre-authorisation',
    tip1: 'When starting a rental, a pre-authorisation is placed on your payment method. This is just to ensure there are sufficient funds available to pay for your rental.',
    tip2: 'Your bank may send you a notification but it is not a charge: only the price of your rental will be charged.',
    tip3: 'Depending on your bank, it can take a few hours up to a few days for the amount to be adjusted on your statements, this is normal.'
  },
  rentcost: {
    rentcosttitle: 'How much does the Rental cost?',
    price1: '{jifei} {currency} per {jifeiDanwei} minuti',
    price2: '{currency} {fengding} per day',
    tip1: 'If you do not return the power bank within {overtime} hours you will be charged a non-return fee of {currency} {yajin} .',
    tip2: 'Duration is rounded to the nearest half hour (30 or 60 minutes).',
    tip3: 'One day rental is 24 hours from the time the power bank is hired.',
    tip4: 'Some venues might offer some free hours or discounts. Enjoy!',
    Days: 'Days',
    Hours: 'Hours',
    Minutes: 'Minutes',
    free: '{mianfei} minutes free',
    willcharged: 'You will be charged {currency} {money}'
  },
  logincode: {
    login: 'Login',
    retry: 'Resend'
  },
  agreenconfirm: {
    title: 'Please confirm authorisation',
    policy: 'Policy.',
    term: "LotusCharge's Terms & Conditions",
    and: 'and',
    privacy: 'Privacy Policy.',
    tips: 'l agree to ',
    cancel: 'CANCEL',
    agree: 'AGREE'
  },
  login: {
    phonenumber: 'Phone Number',
    verificationcode: 'Verification Code',
    Rentpowerbank: 'Rent a power bank',
    Rentpowerbanktip: 'Enter your cell phone number to get started',
    phone_number: 'Please enter an {0}-digit mobile number ',
    captcha_code: 'Code',
    send_code: 'Send Code',
    login: 'Login',
    agree: 'By clicking Login, you agree to ',
    readme: `User Agreement`,
    msg_fail_login: 'Login failed',
    msg_success_login: 'Login successful',
    msg_success_login_code: 'Successfully received, please download the APP to log in to use',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    no_sms: 'Did not receive text message',
    retry: 'Resend',
    receive: 'Apply',
    invite_pwd: 'Invitation code',
    vip_agreen_tip: 'By continuing, you are indicating that you accept our',
    vip_agreen_service: 'Terms of Service',
    vip_agreen_and: 'and',
    vip_agreen_policy: 'Privacy Policy'
  },
  msg: {
    issuccesspreauth: 'Vänligen bekräfta mottagandet av auktoriseringsmeddelande från MB Way och klicka på fullständig auktorisering',
    save_success: 'Spara lyckat',
    none_code: 'Code does not exist',
    sms_fail: 'SMS send failed',
    unlogin: 'You haven\'t logged in yet, please login',
    msg_fail_title: 'Warning',
    deletecard: 'Are you sure to delete this card?',
    loading: 'Loading...',
    title: 'Hint',
    success: 'Successful operation',
    error: 'Unknown error,please try again later.',
    roleerror: 'Insufficient permissions, please consult the administrator',
    neterror: 'Network connection failed. Please try again later',
    confirm: 'Confirm',
    retry: 'Retry',
    cancel: 'Cancel',
    msg_logout: 'Are you sure to exit the account?',
    vieworder: 'View order',
    addorselectcard: 'Please add or select a card package to continue',
    success_payment: 'Payment completed',
    fail_payment: 'Payment failure',
    keyword: 'keyword',
    unpaid_order: 'There are unpaid orders',
    go_pay: 'Go pay',
    no_more: 'no more',
    noshop: 'No merchants',
    msg_fail_code: 'Please enter the verification code',
    msg_fail_phone: 'Please enter your phone number',
    exceedorder: 'You already have an order under lease, please return it before continuing',
    msg_confirm_preAuth: 'Confermi la pre-autorizzazione?',
    msg_confirm_preAuth_fail: 'Si è verificato un errore nel pagamento. Effettua nuovamente il pagamento e conferma nuovamente la pre-autorizzazione.',
    browsernotsupport: 'Din webbläsare stöder inte positionering',
    jumpfail: 'Automatiskt hopp misslyckades, klicka manuellt på knappen för att fortsätta'

  },
  result: {
    title: 'Rental confirmed',
    tips: 'To view orders and other LotusCharge locations,please downloadthe App.',
    download: 'DOWNLOAD',
    msg_result_loading: 'The payment result is being confirmed...',
    msg_tips_loading: 'Portable battery is popping up, please wait patiently...'
  },
  result_msg: {
    loading: 'Loading...',
    refresh: 'Refresh'
  },
  nearby: {
    rent: 'Hyreskontrakt',
    return: 'retur',
    here: 'Gå hit'
  }
}
