<template>
  <div id="app">
    <router-view />
    <van-action-sheet
      v-model="langView"
      :actions="actions"
      @select="onSelect"
    />
    <!-- <select-lang v-model="langView" :connect="false"></select-lang> -->
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
// import { SelectLang } from './components/select'
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
import { ActionSheet } from "vant"
export default {
  components: {
    PopupLoading,
    VanActionSheet: ActionSheet
    // SelectLang
  },
  computed: {
    ...mapState(["loading", "isLangView", "localeArr"]),
    langView: {
      get() {
        return this.isLangView
      },
      set(val) {
        console.log("langview==", val)
        this.$store.commit("isLangView", val)
      }
    }
  },
  data() {
    return {
      actions: []
    }
  },
  methods: {
    onSelect(val) {
      console.log("选择====", val)
      this.$i18n.locale = val.value
      this.$store.commit("lang", val.value)
      this.langView = false
    }
  },
  created() {
    const currDomain = window.location.origin
    this.$store.commit("domain", currDomain)
    let arr = []
    this.localeArr.map((v) => {
      let obj = {
        name: v.label,
        value: v.value,
        disabled: false
      }
      arr.push(obj)
    })
    this.actions = arr
  }
}
</script>

<style lang="less">
html,
body {
  background: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  color: #000;
  font-family: "Montserrat-Medium" !important;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: linear-gradient(to right, #028bd2, #23c8ee);
  font-size: 32px;
  border-radius: 20px;
  border: none;
}
.w-full {
  width: 100%;
}
@font-face {
  font-family: "DIN Condensed Bold"; //自定义字体名称
  src: url("fonts/DIN\ Condensed\ Bold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium"; //自定义字体名称
  src: url("fonts/Montserrat-Medium.ttf"); //字体包路径
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold"; //自定义字体名称
  src: url("fonts/Montserrat-SemiBold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
</style>
