import zhCN from './zh-CN.js'
import enUS from './en-US.js'
import itCH from './it-CH.js'
import deDE from './de-DE.js'
import frFR from './fr-FR.js'
import itIT from './it-IT.js'
import ptPT from './pt-PT.js'

export default {
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS,
    'it-CH': itCH,
    'de-DE': deDE,
    'fr-FR': frFR,
    'it-IT': itIT,
    'pt-PT': ptPT
  }
}
