// 德语
export default {
  home: {
    price_info: '{mianfei} Minuten kostenlos, maximal pro Tag {currencyName}{fengding}, Kaution {currencyName}{yajin}, kostenlose Mietkaution',
    submit: 'Aufladen',
    borrow: 'Ausleihen',
    msg_rentcount: 'Sie haben derzeit {zujieNum} Bestellungen, die nicht geschlossen sind',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    msg_fail_title: 'Fehler',
    msg_fail_content: 'Miete fehlgeschlagen, bitte versuchen Sie es erneut',
    gomap: 'Händler in der Nähe ansehen',
    offline: 'Gerät {devicecode} ist offline, bitte kontaktieren Sie das anwesende Personal'
  },
  loginborrow: {
    complaint: 'Beschwerde',
    paynow: 'Immediate payment',
    claimsettlement: 'Ansprüche und Streitigkeiten',
    daili_error: 'Der aktuelle Agent unterstützt diese Zahlungsmethode nicht. Bitte wechseln Sie den Agenten',
    name: 'Bitte geben Sie den Benutzernamen ein',
    msg_email_err: 'Fehler im E-Mail-Format',
    email: 'Bitte geben Sie die E-Mail-Adresse ein',
    priceInfo: '{currency} {jifei} pro {jifeiDanwei} Minuten',
    powerbanktitle: 'Lassen Sie Ihre Powerbank frei',
    loginwith: 'Anmelden mit',
    msg_fail_title: 'Fehler',
    msg_fail_user: 'Fehler beim Abrufen der Benutzerinformationen. Möchten Sie es erneut versuchen?',
    msg_rentcount: 'Sie haben derzeit {zujieNum} Bestellungen, die nicht geschlossen sind',
    msg_fail_support: 'Das System wird nicht unterstützt',
    msg_fail_confirm: 'Bestätigen',
    msg_fail_retry: 'Wiederholen',
    msg_fail_cancel: 'Abbrechen',
    msg_fail_order: 'Fehler beim Erstellen der Leihbestellung',
    msg_fail_paymod: 'Fehler beim Abrufen der Zahlungsmethode',
    msg_fail_login: 'Anmeldung fehlgeschlagen',
    Apple: 'Apple',
    otheropt: 'Andere Optionen',
    selectmethod: 'Wählen Sie Ihre Zahlungsmethode aus',
    paymentdetail: 'Details zur Zahlungsmethode',
    couponcode: 'Gutscheincode',
    rent: 'Zahlung bestätigen',
    pricing: 'Preise anzeigen',
    phone: 'Telefon',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Mit Facebook anmelden',
    logingg: 'Mit Google anmelden',
    loginapple: 'Mit Apple anmelden',
    loginphone: 'Mit Telefon anmelden',
    captcha_code: 'Code',
    phonenumber: 'Geben Sie Ihre Telefonnummer ein',
    msg_fail_code: 'Bitte geben Sie den Bestätigungscode ein',
    msg_fail_phone: 'Bitte geben Sie Ihre Telefonnummer ein',
    logout: 'Konto verlassen',
    savenow: 'Jetzt sparen',
    delete: 'Löschen',
    addcard: 'Kartendetails eingeben',
    msg_fail_content: 'Miete fehlgeschlagen, bitte versuchen Sie es erneut',
    title: 'Bitte bestätigen Sie die Autorisierung',
    policy: 'Richtlinie.',
    term: ' den Allgemeinen Geschäftsbedingungen zu.',
    and: 'und',
    privacy: 'Datenschutzrichtlinie',
    tips: 'Mit dem Fortfahren stimmen Sie unserer ',
    cancel: 'ABBRECHEN',
    agree: 'ZUSTIMMEN',
    openinbrowser: 'Bitte öffnen Sie es im Browser',
    localtip1: 'DIE BATTERIE AN JEDER LotusCharge ENERGIESTATION ZURÜCKGEBEN',
    pay_error: 'Die Zahlung ist fehlgeschlagen. Bitte versuchen Sie es erneut',
    localtip2: 'Um eine nahegelegene Station zu finden, konsultieren Sie die Karte oder scannen Sie den QR-Code auf der Powerbank.'
  },
  preauth: {
    preauthortitle: 'Über die Vorautorisierung',
    tip1: 'Wenn Sie eine Miete starten, wird eine Vorautorisierung auf Ihre Zahlungsmethode gesetzt. Dies ist nur, um sicherzustellen, dass ausreichend Mittel zur Verfügung stehen, um für Ihre Miete zu bezahlen.',
    tip2: 'Ihre Bank kann Ihnen eine Benachrichtigung senden, aber es ist keine Gebühr: nur der Preis Ihrer Miete wird berechnet.',
    tip3: 'Je nach Bank kann es einige Stunden bis einige Tage dauern, bis der Betrag auf Ihren Kontoauszügen angepasst wird, das ist normal.'
  },
  rentcost: {
    rentcosttitle: 'Wie viel kostet die Miete?',
    price1: '{currency} {jifei} pro {jifeiDanwei} Minuten',
    price2: '{currency} {fengding} pro Tag',
    tip1: 'Wenn Sie die Powerbank innerhalb von {overtime} Stunden nicht zurückgeben, gehört sie Ihnen für {yajin} {currency}.',
    tip2: 'Die Dauer wird auf die nächste halbe Stunde (30 oder 60 Minuten) gerundet.',
    tip3: 'Eine Tagesmiete beträgt 24 Stunden ab dem Zeitpunkt, an dem die Powerbank gemietet wird.',
    tip4: 'Einige Veranstaltungsorte bieten möglicherweise einige kostenlose Stunden oder Rabatte an. Viel Spaß!',
    Days: 'Tage',
    Hours: 'Stunden',
    Minutes: 'Minuten',
    free: '{mianfei} minutes free',
    willcharged: 'Sie werden mit {currency} {money} belastet'
  },
  logincode: {
    login: 'Anmelden',
    retry: 'Erneut senden'
  },
  agreenconfirm: {
    title: 'Bitte bestätigen Sie die Autorisierung',
    policy: 'Richtlinie.',
    term: "LotusCharge's Allgemeine Geschäftsbedingungen",
    and: 'und',
    privacy: 'Datenschutzrichtlinie.',
    tips: 'Ich stimme zu ',
    cancel: 'ABBRECHEN',
    agree: 'ZUSTIMMEN'
  },
  login: {
    phonenumber: 'Telefonnummer',
    verificationcode: 'Verifizierungscode',
    Rentpowerbank: 'Mieten Sie eine Powerbank',
    Rentpowerbanktip: 'Geben Sie Ihre Handynummer ein, um zu beginnen',
    phone_number: 'Bitte geben Sie eine {0}-stellige Handynummer ein ',
    captcha_code: 'Code',
    send_code: 'Code senden',
    login: 'Anmelden',
    agree: 'Mit dem Klick auf Anmelden stimmen Sie zu ',
    readme: `Nutzungsvereinbarung`,
    msg_fail_login: 'Anmeldung fehlgeschlagen',
    msg_success_login: 'Anmeldung erfolgreich',
    msg_success_login_code: 'Erfolgreich erhalten, bitte laden Sie die APP herunter, um sich anzumelden und zu verwenden',
    msg_fail_user: 'Fehler beim Abrufen der Benutzerinformationen. Möchten Sie es erneut versuchen?',
    no_sms: 'Keine Textnachricht erhalten',
    retry: 'Erneut senden',
    receive: 'Bewerben',
    invite_pwd: 'Einladungscode',
    vip_agreen_tip: 'Mit dem Fortfahren erklären Sie sich damit einverstanden, dass Sie unsere akzeptieren',
    vip_agreen_service: 'Nutzungsbedingungen',
    vip_agreen_and: 'und',
    vip_agreen_policy: 'Datenschutzrichtlinie'
  },
  msg: {
    issuccesspreauth: 'Bitte bestätigen Sie den Eingang der Autorisierungsnachricht von MB Way und klicken Sie auf Vollständige Autorisierung',
    save_success: 'Speichern erfolgreich',
    none_code: 'Code existiert nicht',
    sms_fail: 'SMS-Versand fehlgeschlagen',
    unlogin: 'Sie haben sich noch nicht angemeldet, bitte melden Sie sich an',
    msg_fail_title: 'Warnung',
    deletecard: 'Sind Sie sicher, dass Sie diese Karte löschen möchten?',
    loading: 'Laden...',
    title: 'Tipps',
    success: 'Erfolgreiche Operation',
    error: 'Unbekannter Fehler, bitte versuchen Sie es später erneut.',
    roleerror: 'Unzureichende Berechtigungen, bitte wenden Sie sich an den Administrator',
    neterror: 'Netzwerkverbindung fehlgeschlagen. Bitte versuchen Sie es später erneut',
    confirm: 'Bestätigen',
    retry: 'Wiederholen',
    cancel: 'Abbrechen',
    msg_logout: 'Sind Sie sicher, dass Sie das Konto verlassen möchten?',
    vieworder: 'Bestellung anzeigen',
    addorselectcard: 'Bitte fügen Sie eine Karte hinzu oder wählen Sie eine Karte aus, um fortzufahren',
    success_payment: 'Zahlung abgeschlossen',
    fail_payment: 'Zahlungsfehler',
    keyword: 'Schlüsselwort',
    unpaid_order: 'Es gibt unbezahlte Bestellungen',
    go_pay: 'Zum Bezahlen gehen',
    no_more: 'nicht mehr',
    noshop: 'Keine Händler',
    msg_fail_code: 'Bitte geben Sie den Verifizierungscode ein',
    msg_fail_phone: 'Bitte geben Sie Ihre Telefonnummer ein',
    exceedorder: 'Sie haben bereits eine Bestellung unter Miete, bitte geben Sie sie zurück, bevor Sie fortfahren',
    msg_confirm_preAuth: 'Vorautorisierung bestätigen?',
    msg_confirm_preAuth_fail: 'Bei der Zahlung ist ein Fehler aufgetreten. Bitte zahlen Sie erneut und bestätigen Sie die Vorautorisierung erneut.',
    browsernotsupport: 'Ihr Browser unterstützt keine Positionierung',
    jumpfail: 'Automatischer Sprung fehlgeschlagen, bitte manuell auf die Schaltfläche klicken, um fortzufahren'
  },
  result: {
    title: 'Miete bestätigt',
    tips: 'Um Bestellungen und andere LotusCharge-Standorte anzuzeigen, laden Sie bitte die App herunter.',
    download: 'HERUNTERLADEN',
    msg_result_loading: 'Das Zahlungsergebnis wird bestätigt...',
    msg_tips_loading: 'Tragbare Batterie springt heraus, bitte warten Sie geduldig...'
  },
  result_msg: {
    loading: 'Laden...',
    refresh: 'Aktualisieren'
  },
  nearby: {
    rent: 'Leasing',
    return: 'zurück',
    here: 'Hier geht\'s.'
  }
}
